import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const ScoreBarGraph = ({ labels, barData }) => {

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Scores",
                data: barData,
                backgroundColor: "#C4233C",
                borderColor: "#C4233C",
                // borderWidth: 4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom",
            },
        },
    };

    return (
        <div style={{ height: "400px", minHeight: "300px" }}>
            <Bar height="100%" data={data} options={options} />
        </div>
    );
};

export default ScoreBarGraph