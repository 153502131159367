import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"


const ScoreHalfDoughnut = ({ segments, value, customSegmentLabels, segmentColor, segmentStops }) => {
    const customSegmentStops = segmentStops;
    const segmentColors = segmentColor;

    return (
        <div>
            <ReactSpeedometer value={value} customSegmentLabels={customSegmentLabels} width={500} segments={segments} currentValueText=' ' needleColor='#212153' textColor='#212153' minValue={customSegmentStops[0]} maxValue={customSegmentStops[customSegmentStops.length - 1]} customSegmentStops={customSegmentStops} segmentColors={segmentColors} />
        </div>
    )
}

export default ScoreHalfDoughnut