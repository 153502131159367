import React, { useState, useEffect, useContext } from 'react'
import styles from "./Scorecard.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { Context as AuthContext } from "../../context/AuthContext";
import {
    useHistory,
    useLocation,
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import Legend from "../../components/ScorecardLegend/ScoreCardLegend";
import GaugeChart from "../../components/GaugeChart/GaugeChart";
import ScorePie from '../../components/ScorePie/ScorePie';
import ScoreMetric from '../../components/ScoreMetric/ScoreMetric';
import {
    FaHandshake,
    FaRegArrowAltCircleUp,
    FaHourglassStart,
    FaSitemap,
    FaCogs,
    FaRegLightbulb,
    FaChartArea,
    FaIdCard,
} from "react-icons/fa";


const Scorecard = () => {
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [businessId, setBusinessId] = useState("");
    const [businessName, setBusinessName] = useState("");

    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;

        setEmail(user.email);
    }, [user]);

    useEffect(() => {
        if (email) {
            const formData = new FormData();

            formData.append("readByEmailOrId", email);

            const requestOptions = {
                method: "POST",
                headers: {},
                body: formData,
            };

            fetch(
                "https://space.somoafrica.org/api.php?resource=business&action=readByEmailOrId",
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    setBusinessId(data.business[0].id);
                    setBusinessName(data.business[0].displayName);
                    setUserId(data.data[0].userId);
                    setName(data.data[0].user_name.split(" ")[0]);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            console.log("I am not boarding");
        }
    }, [email]);


    const metrics = [
        {
            index: 0, link: "business-risk", key: "businessRisk", name: "Business Risk", score: "85", icon: <FaChartArea size="6em" />, iconColor: "#851122", color: "#C4233C", desc: "The possibility that a company's operations or business model will fail to generate sufficient profits or cash flows to meet its financial obligations.",
            metrics: [
                { index: 0, name: "Operating profit margin", chart: "bar", labels: ["April", "May", "Jun", "Jul", "Aug", "Sep"], data: [227646, 7085, 295397, -134950, 187603, 118222], desc: "A measure of how much money a business makes from its products or services after subtracting all direct and indirect costs." },
                {
                    index: 1, name: "Timeliness of reporting cashflows", chart: "halfdoughnut", segments: 3, desc: "A measure of how quickly and accurately a business provides information about its cash flows", legend: [{ color: '#00A5A2', title: "Never missed a submission in the last 3 months" }, { color: '#FFC83B', title: "Turned in some cash flow in the last 3 months" }, { color: '#C4233C', title: "Did not turn in cash flow at all in the last 3 months" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [0, 1, 2, 3], value: 2.5
                },
                { index: 2, name: "Debt Service Cover ratio", chart: "bar", labels: ["April", "May", "Jun", "Jul", "Aug", "Sep"], data: [2, -1, 2, -2, 2, 1], desc: "Measures a business's available cash flow to pay current debt obligations" },
                {
                    index: 3, name: "Licenses/Permits", chart: "halfdoughnut", segments: 2, desc: "Availability of county licenses and industry permits",
                    legend: [{ color: '#00A5A2', title: "Has licenses" }, { color: '#C4233C', title: "Does not have licenses" }], customSegments: [
                        { text: 'No', position: 'OUTSIDE' },
                        { text: 'Yes', position: 'OUTSIDE' },
                    ], segmentColors: ['#C4233C', '#00A5A2'], segmentStops: [-1, 0, 1], value: 0.5
                }, { index: 4, name: "Inventory-to-Installment Ratio", chart: "bar", labels: ["April", "May", "Jun", "Jul", "Aug", "Sep"], data: [2, -1, 2, 0, 2, 1], desc: "Measure the ability to repay the installment upon sale of the available inventory" }, { index: 5, name: "Monthly Average Cashflow-to-Loan Amount Ratio", chart: "bar", labels: ["April", "May", "Jun", "Jul", "Aug", "Sep"], data: [2, 1, 2, 1, 2, 2], desc: "Monthly average cashflows for 3 months/loan amount" }, {
                    index: 6, name: "Business coach/Advisory score", chart: "halfdoughnut", segments: 2, desc: "How often the client has received coaching services",
                    legend: [{ color: '#C4233C', title: "Have met with business coach within the quarter" }, { color: '#00A5A2', title: "Has not met with business coach within the quarter" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#00A5A2'], segmentStops: [-1, 0, 1], value: 0.5
                }]
        },
        {
            index: 1, link: "investment-risk", name: "Investment Risk", score: "91", icon: <FaRegLightbulb size="6em" />, iconColor: "#c99a22", color: "#FFC83B", desc: "The potential for an investor or funder to experience financial losses or receive lower returns than expected due to various factors.",
            metrics: [{
                index: 0, name: "Loan Term", chart: "halfdoughnut", segments: 3, desc: "The length of time over which an entrepreneur is expected to repay a loan for the specific loan or credit facility.",
                legend: [{ color: '#00A5A2', title: "Less than 18 months" }, { color: '#FFC83B', title: "18 to 35 months" }, { color: '#C4233C', title: "Greater than 35 months" }], customSegments: [{ text: '3', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [1, 2, 3, 4], value: 3.5
            }, {
                index: 1, name: "Owners' contribution towards a project", chart: "halfdoughnut", segments: 4, desc: "Entrepreneur's contribution to support a specific project or funding.",
                legend: [{ color: '#00A5A2', title: "Deposit less than or equal to 50%" }, { color: '#FFC83B', title: "Deposit less than 30%" }, { color: '#CC6328', title: "Deposit less than 20%" }, { color: '#C4233C', title: "Deposit 10%" }], customSegments: [{ text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }, { text: '3', position: 'OUTSIDE' }, { text: '4', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [1, 2, 3, 4, 5], value: 3.5
            }]
        },
        {
            index: 2, link: "credit-risk", name: "Credit Risk", score: "97", icon: <FaSitemap size="6em" />, iconColor: "#b55a25", color: "#CC6328", desc: "The possibility that the borrower may fail to fulfill their payment obligations, whether partially or entirely.",
            metrics: [{
                index: 0, name: "Loan Repayment", chart: "halfdoughnut", segments: 3, desc: "The pattern of payment by the entrepreneur during the loan tenure",
                legend: [{ color: '#00A5A2', title: "Spent between 0 to 30 days or paid in advance" }, { color: '#FFC83B', title: "Not a previous Somo entrepreneur" }, { color: '#C4233C', title: "Spent over 90 days at risk" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [-1, 0, 1, 2], value: 1.5
            }, {
                index: 1, name: "Credit Reference Bureau", chart: "halfdoughnut", segments: 3, desc: "A score based on rating provided by a regulated  Credit Risk Bureau (CRB) for defaulters",
                legend: [{ color: '#00A5A2', title: "Clean CRB record and evidence of several loans repaid successfully" }, { color: '#FFC83B', title: "No CRB record/If the amount is less than Kes.300,000/=" }, { color: '#C4233C', title: "Negative CRB rating" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [-1, 0, 1, 2], value: 1.5
            }, {
                index: 2, name: "Supplier's reference", chart: "halfdoughnut", segments: 4, desc: "An evaluation of the number of suppliers an entrepreneur has defaulted on.",
                legend: [{ color: '#00A5A2', title: "2 or more suppliers say no default" }, { color: '#FFC83B', title: "1 supplier says no default" }, { color: '#CC6328', title: "1 supplier says yes to default" }, { color: '#C4233C', title: "2 suppliers say yes to default" }], customSegments: [{ text: '-2', position: 'OUTSIDE' }, { text: '-1', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [-2, -1, 0, 1, 2], value: 1.5
            }, {
                index: 3, name: "Offtaker/irrevocable undertaking", chart: "halfdoughnut", segments: 2, desc: "Current contracts from contractors or suppliers, along with the payment schedule for amounts paid directly to Somo.",
                legend: [{ color: '#00A5A2', title: "Has business contracts from a contractor" }, { color: '#C4233C', title: "Does not have business contracts from a contractor" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#00A5A2'], segmentStops: [-1, 0, 1], value: 0.5
            }]
        },
        {
            index: 3, link: "performance-risk", name: "Performance Risk", score: "87", icon: <FaCogs size="6em" />, iconColor: "#017674", color: "#00A5A2", desc: "Possibility that the project may fail to achieve its intended goals or deliverables as a result of insufficient performance by an individual.",
            metrics: [{
                index: 0, name: "Succession planning", chart: "halfdoughnut", segments: 3, desc: "A designated individual who is trained to manage the business when the owner is unavailable",
                legend: [{ color: '#00A5A2', title: "Strong succession plan in place" }, { color: '#FFC83B', title: "No succession plan but key person dependency is not significant" }, { color: '#C4233C', title: "No succession plan and high key person dependency is significant " }], customSegments: [{ text: '-2', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [-2, -1, 0, 1], value: 0.5
            }, {
                index: 1, name: "Experience Skills", chart: "halfdoughnut", segments: 3, desc: "Experienced personnel in terms of number of years operating the business",
                legend: [{ color: '#00A5A2', title: "Business operated for more than 5 years" }, { color: '#FFC83B', title: "Business operated between 1-5 years" }, { color: '#C4233C', title: "Business operated for less than 1 year" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [-2, -1, 0, 1], value: 0.5
            }]
        },
        {
            index: 4, link: "social-data-via-loan-proposal", name: "Social Data via Loan Proposal", score: "96", icon: <FaHourglassStart size="6em" />, iconColor: "#141433", color: "#212153", desc: "Social data from the community.",
            metrics: [{
                index: 0, name: "Cluster counter-guarantee", chart: "halfdoughnut", segments: 3, desc: "Guarantee of the facility by atleast 1 peer member within the same group under Somo grouping",
                legend: [{ color: '#00A5A2', title: "Guarantee of the facility by atleast 1 or more peer members" }, { color: '#FFC83B', title: "Guarantee of the facility by atleast 1 peer member" }, { color: '#C4233C', title: "No guaranter" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [0, 1, 2, 3], value: 2.5
            }, {
                index: 1, name: "Leadership in church/groups/chamas", chart: "halfdoughnut", segments: 2, desc: "The entrepreneur is a leader  to the community outside the Somo community",
                legend: [{ color: '#00A5A2', title: "Is a leader  to the community outside the Somo community" }, { color: '#C4233C', title: "Is not a leader  to the community outside the Somo community" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#00A5A2'], segmentStops: [-1, 0, 1], value: 0.5
            }, {
                index: 2, name: "Team leader score (Entrepreneur lead)", chart: "halfdoughnut", segments: 3, desc: "The team leader's score on the commitment of the entrepreneur",
                legend: [{ color: '#00A5A2', title: "Met with coach three times or more in the quarter " }, { color: '#FFC83B', title: "Met with coach less than three times in the quarter" }, { color: '#C4233C', title: "Did not meet with coach during the quarter" }], customSegments: [{ text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#FFC83B', '#00A5A2'], segmentStops: [0, 1, 2, 3], value: 2.5
            }]
        },
        {
            index: 5, link: "impact-data-via-digikua", name: "Impact Data via DigiKua", score: "95", icon: <FaHandshake size="6em" />, iconColor: "#851122", color: "#C4233C", desc: "Social impact data.",
            metrics: [{
                index: 0, name: "Women employed", chart: "halfdoughnut", segments: 4, desc: "The number of women currently employed by the entrepreneur",
                legend: [{ color: '#00A5A2', title: "More than 2 women employed" }, { color: '#FFC83B', title: "2 women employed" }, { color: '#CC6328', title: "1 woman employed" }, { color: '#C4233C', title: "0 women employed" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [-1, 0, 1, 2, 3], value: 2.5
            }, {
                index: 1, name: "Youth employed", chart: "halfdoughnut", segments: 4, desc: "The number of youth currently employed by the entrepreneur",
                legend: [{ color: '#00A5A2', title: "More than 2 youths employed" }, { color: '#FFC83B', title: "2 youths employed" }, { color: '#CC6328', title: "1 youth employed" }, { color: '#C4233C', title: "0 youths employed" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [-1, 0, 1, 2, 3], value: 2.5
            }, {
                index: 2, name: "Number of direct jobs created", chart: "halfdoughnut", segments: 4, desc: "Jobs related directly to the business (full time + part time + casual)",
                legend: [{ color: '#00A5A2', title: "More than 3 direct jobs created" }, { color: '#FFC83B', title: "3 direct jobs created" }, { color: '#CC6328', title: "1 or 2 direct jobs created" }, { color: '#C4233C', title: "No direct job created" }], customSegments: [{ text: '-1', position: 'OUTSIDE' }, { text: '0', position: 'OUTSIDE' }, { text: '1', position: 'OUTSIDE' }, { text: '2', position: 'OUTSIDE' }], segmentColors: ['#C4233C', '#CC6328', '#FFC83B', '#00A5A2'], segmentStops: [-1, 0, 1, 2, 3], value: 2.5
            }, { index: 3, name: "Timeliness of reporting quarterly survey", chart: "bar", labels: ["Q4-2023", "Q1-2024", "Q2-2024", "Q3-2024"], data: [4, 4, 4, 5], desc: "Number of quarterly surveys submitted in the last 4 quarters" }]
        },
    ]
    const history = useHistory();
    const { path } = useRouteMatch();
    const location = useLocation();
    const [averageScore, setAverageScore] = useState(92);
    const [hovered, setHovered] = useState("");
    const [metricId, setMetricId] = useState("");
    const [metricName, setMetricName] = useState("");
    const [metricScore, setMetricScore] = useState("");
    const [metricIcon, setMetricIcon] = useState();
    const [metricDesc, setMetricIconDesc] = useState("");
    const [metricMetrics, setmetricMetrics] = useState({});
    const [metricKey, setmetricKey] = useState("");
    const [baseUrl, setBaseUrl] = useState("https://digikua.co.ke/api/");

    const [scoresError, setScoresError] = useState("");
    const [isLoadingScores, setIsLoadingScores] = useState(false);
    const [scoresData, setScoresData] = useState([]);
    const [selectedData, setSelectedData] = useState({});

    const viewMetric = (link) => {
        const data = metrics.find(metric => metric.link === link)
        setSelectedData(data);

        history.push(`${path}/${link}`);
    }

    const getScores = async () => {
        try {
            setScoresData([]);
            setIsLoadingScores(true);
            setScoresError("");

            const dt = {
                "businessRisk": true,
                "investmentRisk": true,
                "creditRisk": true,
                "performanceRisk": true,
                "socialDataViaLoanProposal": true,
                "impactDataViaDigiKua": true
            }

            const requestOptions = {
                method: "POST",
                headers: {},
                body: JSON.stringify(dt),
            };

            const url = `${baseUrl}?resource=scorecard_v2&action=read`;
            const res = await fetch(url, requestOptions);

            if (!res.ok) {
                throw new Error("Something went wrong with fetching scores");
            }

            const data = await res.json();
            if (data.Response === "False") {
                throw new Error("Scores not found");
            }
            if (data.data) {
                setScoresData(data.data);
                setScoresError("");
                setIsLoadingScores(false);
            }
        } catch (error) {
            setScoresError("");
            setIsLoadingScores(false);
        } finally {
            setIsLoadingScores(false);
        }
    }

    useEffect(() => {
        getScores();
    }, [])

    return (
        <>
            <Dashboard sidebarRoutes={clientRoutes} location={location}>
                <div className={styles.header}>
                    <h2>Scorecard . {businessName ? businessName : ""}</h2>
                </div>
                <Switch>
                    <Route exact path={`${path}`}>
                        <div className={styles.piechart}>
                            <div className={styles.pieChartContainer}>
                                <div className={styles.scoresPieChart}>
                                    <ScorePie averageScore={averageScore} legend={true} />
                                </div>
                            </div>
                            <div className={styles.scoreContainer}>
                                <h3>Overall average score is</h3>
                                <h2 className={styles.description}>{parseFloat(averageScore)} Excellent</h2>
                            </div>
                        </div>
                        <div className={styles.metricsContainer}>
                            {metrics.map((metric, index) => (
                                <div
                                    className={styles.metric}
                                    key={index}
                                    style={{
                                        backgroundColor: metric.color,
                                        transform: hovered === metric.index && "scale(1.05",
                                    }}
                                    onMouseEnter={() => setHovered(metric.index)}
                                    onMouseLeave={() => setHovered("")}
                                    onClick={() => {
                                        setHovered("")
                                        viewMetric(metric.link)
                                    }}>
                                    <div className={styles.top}>
                                        <p className={styles.score}>{metric.score}</p>
                                        <FaRegArrowAltCircleUp
                                            className={styles.openMetric}
                                            size="2rem"
                                            color={metric.iconColor}
                                            style={{ transform: hovered === metric.index ? "scale(1.1) rotate(45deg)" : "rotate(45deg)" }}
                                        />
                                    </div>
                                    <p className={styles.name}>{metric.name}</p>
                                    <div className={styles.icon}><p style={{ color: metric.iconColor }}>{metric.icon}</p></div>
                                </div>
                            ))}
                        </div>
                    </Route>
                    <Route path={`${path}/:id`}>
                        <ScoreMetric baseUrl={baseUrl} selectedData={selectedData} setSelectedData={setSelectedData} metrics={metrics} viewMetric={viewMetric} businessId={businessId} businessName={businessName} />
                    </Route>
                </Switch>
            </Dashboard>
        </>
    )
}

export default Scorecard;