import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import styles from "./ScoreMetric.module.scss"
import {
    FaRegArrowAltCircleUp,
    FaPlus,
    FaMinus,
    FaHandshake,
    FaHourglassStart,
    FaSitemap,
    FaCogs,
    FaRegLightbulb,
    FaChartArea,
} from "react-icons/fa";
import {
    useRouteMatch,
} from "react-router-dom";
import ScoreBarGraph from '../ScoreBarGraph/ScoreBarGraph';
import ScorePie from '../ScorePie/ScorePie';
import GaugeChart from '../GaugeChart/GaugeChart';
import CreditPie from '../CreditPie/CreditPie';
import ScoreHalfDoughnut from '../ScoreHalfDoughnut/ScoreHalfDoughnut';
import Legend from "../../components/ScorecardLegend/ScoreCardLegend";


const ScoreMetric = ({ baseUrl, selectedData, setSelectedData, metrics, viewMetric, businessName, businessId }) => {
    const [selectedMetric, setSelectedMetric] = useState()
    const [scoresError, setScoresError] = useState("");
    const [isLoadingScores, setIsLoadingScores] = useState(false);
    const [scoresData, setScoresData] = useState([]);
    const { path } = useRouteMatch();
    const { id } = useParams();


    useEffect(() => {
        const data = metrics.find(metric => metric.link === id)
        setSelectedData(data);
    }, [])

    const getScores = async (key) => {
        try {
            setScoresData([]);
            setIsLoadingScores(true);
            setScoresError("");

            const dt = {
                [key]: true,
            }

            const requestOptions = {
                method: "POST",
                headers: {},
                body: JSON.stringify(dt),
            };

            const url = `${baseUrl}?resource=scorecard_v2&action=read`;
            const res = await fetch(url, requestOptions);

            if (!res.ok) {
                throw new Error("Something went wrong with fetching scores");
            }

            const data = await res.json();
            if (data.Response === "False") {
                throw new Error("Scores not found");
            }
            if (data.data) {
                setScoresData(data.data);
                setScoresError("");
                setIsLoadingScores(false);
            }
        } catch (error) {
            setScoresError("");
            setIsLoadingScores(false);
        } finally {
            setIsLoadingScores(false);
        }
    }
    useEffect(() => {
        if (!selectedData) return;
        getScores(selectedData.index);
    }, [selectedData])

    const getLink = (index, action) => {
        const id = action === "add" ? index + 1 : index - 1;
        const data = metrics.find(metric => metric.index === id);
        return data.link;
    }


    return (
        <div className={styles.scoreMetric}>
            <div className={styles.bizInfo}>
                <div className={styles.biz}>
                    <h5>Business Name:</h5>
                    <h5 className={styles.bizAns}>{businessName}</h5>
                </div>
                <div className={styles.biz}>
                    <h5>Business Status:</h5>
                    <h5 className={styles.bizAns}>Active</h5>
                </div>
                <div className={styles.biz}>
                    <h5>Last Updated:</h5>
                    <h5 className={styles.bizAns}>2024-10-01</h5>
                </div>
            </div>
            <div className={styles.metricInfo}>
                <div className={styles.metricTop}>
                    <div className={styles.nextIcons}>
                        <div className={styles.icon}><FaRegArrowAltCircleUp onClick={() => {
                            setSelectedMetric("");
                            viewMetric(getLink(selectedData.index === 0 ? metrics.length : selectedData.index, "sub"))
                        }
                        } color='#ffffff' size="3rem" style={{ transform: "rotate(270deg)" }} /></div>
                        <div className={styles.name}><h3>{selectedData.name}</h3></div>
                        <div className={styles.icon} style={{ display: "flex", justifyContent: "end" }}><FaRegArrowAltCircleUp onClick={() => {
                            setSelectedMetric("");
                            viewMetric(getLink(selectedData.index === metrics.length - 1 ? -1 : selectedData.index, "add"))
                        }} color='#ffffff' size="3rem" style={{ transform: "rotate(90deg)", }} /></div>
                    </div>

                    <h3 className={styles.score}>Current Score: {selectedData.score}</h3>
                    <div className={styles.description}>
                        <p>{selectedData.icon}</p>
                        <p className={styles.desc}>{selectedData.desc}</p>
                    </div>
                </div>
                <div className={styles.metricBottom}></div>
            </div>

            <div className={styles.metrics}>
                {selectedData && selectedData.metrics ? (
                    selectedData.metrics.map((metric, index) => (
                        <div key={index}>
                            <div className={styles.metric} onClick={() => {
                                setSelectedMetric(selectedMetric === metric.index ? "" : metric.index);
                            }}>
                                <p>{metric.name}</p>
                                {metric.index === selectedMetric ? <FaMinus color='#000000' size="2rem" /> : <FaPlus color='#000000' size="2rem" />}
                            </div>
                            <div className={`${styles.metricDescription} ${metric.index === selectedMetric ? styles.show : styles.hide}`}>
                                <div className={styles.metricDesc}>
                                    <p>{metric.desc}</p>
                                </div>
                                <div>{metric.chart === "bar" && (
                                    <div className={styles.bar}><ScoreBarGraph labels={metric.labels} barData={metric.data} /></div>
                                )}</div>
                                <div className={styles.metricChart}>
                                    {metric.chart === "pie" && (
                                        <div className={styles.pie}>
                                            <CreditPie pieData={metric.data} colors={metric.colors} labels={metric.labels} />
                                        </div>
                                    )}
                                    {metric.chart === "halfPie" && (
                                        <div className={styles.pie}>
                                            <ScorePie averageScore={69.76} legend={false} />
                                        </div>
                                    )}

                                    <div className={styles.halfDoughnut}>
                                        {metric.chart === "halfdoughnut" && (
                                            <div className={styles.legend}>
                                                {metric.legend.map((leg, index) => (
                                                    <Legend key={index} bgColor={leg.color} color="#000000" name={leg.title} />
                                                ))}
                                            </div>
                                        )}
                                        {metric.chart === "halfdoughnut" && (
                                            <div className={styles.pie}>
                                                <ScoreHalfDoughnut segments={metric.segments} value={metric.value} customSegmentLabels={metric.customSegments} segmentColor={metric.segmentColors} segmentStops={metric.segmentStops} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No data available</div>
                )}
            </div>


        </div >
    )
}

export default ScoreMetric