import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend);


const ScorePie = ({ averageScore, legend }) => {
    const data = {
        labels: [
            'Bad',
            'Average',
            'Good',
            'Excellent'
        ],
        datasets: [{
            label: 'My First Dataset',
            data: [25, 25, 25, 25],
            backgroundColor: [
                '#C4233C',
                '#CC6328',
                '#FFC83B',
                '#00A5A2'
            ],
            circumference: 180,
            rotation: 270,
            hoverBorderColor: "#000000",
            needleValue: averageScore
        }]
    };
    const gaugeNeedle = {
        id: "gaugeNeedle",
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart;
            ctx.save();

            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
            const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
            const widthSlice = (outerRadius - innerRadius) / 10;
            const radius = 15;
            const angle = Math.PI / 180;

            const needleValue = data.datasets[0].needleValue;

            const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0)

            const circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue

            ctx.translate(xCenter, yCenter);
            ctx.rotate(Math.PI * (circumference + 1.5))

            // Needle
            ctx.beginPath();
            ctx.strokeStyle = '#000000';
            ctx.fillStyle = '#FFC83B';
            ctx.lineWidth = 3;
            ctx.moveTo(0 - radius, 0);
            ctx.lineTo(0, 0 - innerRadius - widthSlice);
            ctx.lineTo(0 + radius, 0);
            ctx.stroke();
            ctx.fill();

            //Dot
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, angle * 360, false);
            ctx.fillStyle = '#000000';
            ctx.fill();
            ctx.stroke();


            ctx.restore();
        }


    }

    const segmentLabelsPlugin = {
        id: 'segmentLabels',
        afterDatasetsDraw(chart) {
            const { ctx } = chart;
            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const radius = chart.getDatasetMeta(0).data[0].outerRadius + 10;
            const labels = ['0', '25', '50', '75', '100'];

            const positions = [
                { x: xCenter - radius + 10, y: yCenter },
                { x: xCenter - (radius * 0.7), y: yCenter - (radius * 0.7) - 10 },
                { x: xCenter, y: yCenter - radius - 10 + 8 },
                { x: xCenter + (radius * 0.7), y: yCenter - (radius * 0.7) - 10 },
                { x: xCenter + radius - 30, y: yCenter },
            ];

            ctx.save();
            ctx.font = "bold";
            ctx.fillStyle = "#212153";

            labels.forEach((label, i) => {
                const { x, y } = positions[i];
                ctx.fillText(label, x, y);
            });

            ctx.restore();
        }
    };

    const plugins = [gaugeNeedle, segmentLabelsPlugin]


    const options = {
        plugins: {
            legend: {
                display: legend,
            },
            tooltip: {
                enabled: false,
            }
        },
    }


    return (
        <div style={{ width: "100%" }} >
            <Doughnut data={data} options={options} plugins={plugins} width="400px" height="400px"></Doughnut>
        </div >
    )
}

export default ScorePie