import React from 'react'
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend);

const CreditPie = ({ pieData, colors, labels, }) => {
    const data = {
        labels: labels,
        datasets: [
            {
                data: pieData,
                backgroundColor: colors,
                borderColor: ['#fff', '#fff'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
        },
    };

    return (
        <div style={{ width: '50%', margin: '0 auto' }}>
            <Pie data={data} options={options} />
        </div>
    );
}

export default CreditPie